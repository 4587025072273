import '../styles/global.css';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import * as React from 'react';
import { ErrorBoundary } from '@dx-ui/osc-error-boundary';
import { ErrorFallback } from '../components/error';
import type { AppContext, AppProps as NextAppProps } from 'next/app';
import {
  publicRuntimeConfig,
  serverRuntimeConfig,
  CONFIG_ENGINE_RULES,
  REACT_QUERY_CONFIG,
} from '../constants';
import { QueryProvider } from '@dx-ui/framework-react-query';
import type { TaggedConfig, ConfigRuleProps } from '@dx-ui/framework-config-rule-provider';
import { ConfigRuleProvider, getServerFeatureConfigs } from '@dx-ui/framework-config-rule-provider';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import { useRouter } from 'next/router';
import type { DehydratedState } from '@tanstack/react-query';
import type { SSRConfig } from 'next-i18next';
import type { IncomingMessage, ServerResponse } from 'http';
import { getCookie } from '@dx-ui/utilities-cookies';
import { useManualPageDynatrace } from '@dx-ui/framework-dynatrace';

const APP_NAME = 'dx-shop-search-ui';

type AppProps = NextAppProps<
  {
    dehydratedState: DehydratedState;
  } & Pick<
    React.ComponentProps<typeof QueryProvider>,
    'customHeaders' | 'referrer' | 'oneLinkConfig'
  > &
    SSRConfig
> & { taggedConfig: TaggedConfig };

function App({ Component, pageProps, router, taggedConfig }: AppProps) {
  const routerLocale = useRouter().locale || router.locale || 'en';

  /**
   * HACK!
   * Store rule config in a ref so that it only references the server side
   * results of rule config, when transitioning pages getInitialProps runs client side
   * without the Akamai response headers so the values return to the default
   * By storing in a ref we are only using the first CSR rule config, not subsequent calls
   */
  const taggedConfigRef = React.useRef(taggedConfig || ({} as TaggedConfig));

  // client side config rules will just mirror server side rules for now
  const options = {
    endpoint: publicRuntimeConfig.BROWSER_DX_GQL_URI,
    appName: publicRuntimeConfig.APP_NAME,
    initialConfigs: taggedConfigRef.current,
  };
  useManualPageDynatrace(router.pathname, APP_NAME);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryProvider
        {...REACT_QUERY_CONFIG}
        customHeaders={pageProps.customHeaders}
        dehydratedQueryState={pageProps.dehydratedState}
        referrer={pageProps.referrer}
        referrerPolicy="no-referrer-when-downgrade"
        oneLinkConfig={pageProps.oneLinkConfig}
        routerLocale={routerLocale}
        handleRequestHeaders={(req) => {
          const mockScenarioHeader = getCookie('dx-mock-scenario', req?.headers?.cookie);
          if (mockScenarioHeader) {
            return { 'dx-mock-scenario': mockScenarioHeader };
          }
        }}
      >
        <LocationProvider api={publicRuntimeConfig.LOCATION_PROVIDER_API}>
          <ConfigRuleProvider {...options}>
            <Component {...pageProps} />
          </ConfigRuleProvider>
        </LocationProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

App.getInitialProps = async (ctx: AppContext) => {
  const {
    ctx: { res, req },
    router: { query },
  } = ctx;

  // get SS config rules
  const options: ConfigRuleProps = {
    endpoint: serverRuntimeConfig.SERVER_DX_GQL_URI,
    appName: publicRuntimeConfig.APP_NAME,
    rules: CONFIG_ENGINE_RULES,
  };

  const taggedConfig = await getServerFeatureConfigs(
    req as IncomingMessage,
    res as ServerResponse,
    query,
    options
  ).catch(() => undefined);

  const pageProps = { taggedConfig };

  return {
    pageProps,
    query,
    taggedConfig,
  };
};

export default appWithTranslation(App);
