import { publicRuntimeConfig, serverRuntimeConfig } from './utils';
export const REACT_QUERY_CONFIG = Object.freeze({
  appId: publicRuntimeConfig.DX_AUTH_API_CUSTOMER_APP_ID,
  appName: publicRuntimeConfig.APP_NAME,
  incompleteLoggingEnabled: publicRuntimeConfig.RUMCS_CAPTURE_CUSTOM_ERRORS === 'enabled',
  gqlEndpoints: {
    browser: publicRuntimeConfig.BROWSER_DX_GQL_URI,
    server: serverRuntimeConfig.SERVER_DX_GQL_URI,
  },
  authEndpoints: {
    browser: publicRuntimeConfig.DX_AUTH_API_CLIENT_URI,
    server: serverRuntimeConfig.DX_AUTH_API_SERVER_URI,
  },
});
