import { v4 as uuidv4 } from 'uuid';
import type { ConstraintErrors } from '../../constants/query-parameters';
import type { QueryParameters } from '../../constants';
import { QUERY_PARAMS_DEFAULT_OBJ } from '../../constants/mock-data';
import type { SearchUrlParams } from './url-parser.types';
import cloneDeep from 'lodash/cloneDeep';
import {
  defaultConstraintErrors,
  freeFormParams,
  corporateCodeRegex,
  groupAndPromoCodeRegex,
  validTokens,
  rateParams,
  specPlanRegex,
  sixPieceDateParams,
  singleDigitRegex,
  specialRateTokensRegex,
  pndRegex,
  corporateParams,
  promoCodeParams,
  datesFlexParams,
  booleanParams,
  occpuancyParams,
  arrivalDateParams,
  departureDateParams,
  arrayTypeParams,
  BOOL_STRING_VALUE,
  offerIdRegex,
  onlyDigitsRegex,
  supportedOfferIdPndLocales,
  MAX_POINTS_LOW_LIMIT,
  filterParams,
} from './url-parser.constants';
import {
  buildTokenFromRateParams,
  parseMultiRoom,
  isValidAdults,
  isValidChildren,
  checkDayUseWithPoints,
  validateAndFormatArrivalDepartureDates,
  convertToBoolean,
  checkTMTPConstraints,
  isValidFreeFormValue,
} from './url-parser.utils';
import type { SortByDropDownValues } from '../../utils';
import type { TripAdvisorRatings } from '../../components/filters/filter.constants';
import type { GeocodePlaceType } from '@dx-ui/gql-types';

export type SearchUrlParser = {
  parsedSearchQueryParams: QueryParameters;
  constraintErrors: ConstraintErrors;
};

export type UrlParamType = string | string[] | undefined;

export const searchUrlParser = ({
  nextRouterUrlParamsObject,
  language,
}: {
  nextRouterUrlParamsObject: Partial<SearchUrlParams> | Record<string, UrlParamType>;
  language: string;
}): SearchUrlParser => {
  const searchWithoutQuery = !nextRouterUrlParamsObject?.query;
  const parsedSearchQueryParams: QueryParameters = {
    ...QUERY_PARAMS_DEFAULT_OBJ,
    arrivalDate: !searchWithoutQuery
      ? QUERY_PARAMS_DEFAULT_OBJ.arrivalDate
      : (nextRouterUrlParamsObject?.arrivalDate as string),
    departureDate: !searchWithoutQuery
      ? QUERY_PARAMS_DEFAULT_OBJ.departureDate
      : (nextRouterUrlParamsObject?.departureDateDate as string),
    activeFiltersState: cloneDeep(QUERY_PARAMS_DEFAULT_OBJ.activeFiltersState),
    rooms: [...QUERY_PARAMS_DEFAULT_OBJ.rooms],
    token: [],
    specPlan: [],
    specialRateTokens: [],
    offerId: 0,
    viewTxns: false,
  };

  const constraintErrors: ConstraintErrors = { ...defaultConstraintErrors };
  // next router object parsing
  for (const param of Object.keys(nextRouterUrlParamsObject)) {
    // dont decode query object vals parsed as arrays
    const nextRouterUrlParamValue = !arrayTypeParams.includes(param)
      ? decodeURIComponent(nextRouterUrlParamsObject[param] as string)
      : nextRouterUrlParamsObject[param];
    const paramStringValue = nextRouterUrlParamValue as string;

    if (param === 'primarySlug') {
      parsedSearchQueryParams[param] = paramStringValue;
    } else if (arrivalDateParams.includes(param)) {
      parsedSearchQueryParams.arrivalDate = paramStringValue;
    } else if (departureDateParams.includes(param)) {
      parsedSearchQueryParams.departureDate = paramStringValue;
    } else if (sixPieceDateParams.includes(param)) {
      parsedSearchQueryParams[param] = paramStringValue;
    } else if (param === 'brandName') {
      const brandName = paramStringValue;
      parsedSearchQueryParams.brandName = brandName;
    } else if (param === 'viewTxns') parsedSearchQueryParams.viewTxns = true;
    // As per discussion w/ product if the user puts 2 of these values in the same URL we will just use the second
    else if (param === 'query' || param === 'searchQuery') {
      parsedSearchQueryParams.query = paramStringValue;
    }

    // freeform string params (no booleans allowed)
    else if (freeFormParams.includes(param)) {
      if (
        (param === 'corporateCode' || param === 'promotionCode') &&
        BOOL_STRING_VALUE.includes(paramStringValue?.toLocaleLowerCase())
      ) {
        // when pnd exists corporateCode is true or false, when offerId exists promotionCode is true or false
        param === 'corporateCode'
          ? (parsedSearchQueryParams.corporateCode = paramStringValue)
          : (parsedSearchQueryParams.promoCode = paramStringValue);
      } else if (isValidFreeFormValue(paramStringValue)) {
        if (corporateParams.includes(param)) {
          if (corporateCodeRegex.test(paramStringValue))
            parsedSearchQueryParams.corporateCode = paramStringValue;
          else constraintErrors.corporateCode = true;
        } else if (promoCodeParams.includes(param)) {
          if (groupAndPromoCodeRegex.test(paramStringValue))
            parsedSearchQueryParams.promoCode = paramStringValue;
          else constraintErrors.promoCode = true;
        } else if (param === 'groupCode') {
          if (groupAndPromoCodeRegex.test(paramStringValue))
            parsedSearchQueryParams.groupCode = paramStringValue;
          else constraintErrors.groupCode = true;
        } else if (param === 'pnd') {
          if (supportedOfferIdPndLocales.includes(language))
            if (pndRegex.test(paramStringValue)) parsedSearchQueryParams.pnd = paramStringValue;
            else constraintErrors.pnd = true;
        } else if (param === 'WT.mc_id') {
          parsedSearchQueryParams.wtmcid = paramStringValue;
        } else parsedSearchQueryParams[param] = paramStringValue;
      }
    } else if (param === 'offerId' && supportedOfferIdPndLocales.includes(language)) {
      offerIdRegex.test(paramStringValue)
        ? (parsedSearchQueryParams.offerId = parseInt(paramStringValue))
        : (constraintErrors.offerId = true);
    } else if (param === 'spec_plan') {
      const specPlan = paramStringValue.split(',');
      specPlan.some((rate) => !specPlanRegex.test(rate))
        ? (constraintErrors.specPlan = true)
        : (parsedSearchQueryParams.specPlan = specPlan);
    } else if (param === 'fromId' && !!paramStringValue && paramStringValue.length <= 20) {
      parsedSearchQueryParams.fromId = paramStringValue;
    } else if (param === 'specialRateTokens') {
      const specialRateTokens = paramStringValue.split(',');
      specialRateTokens.some((rate) => !specialRateTokensRegex.test(rate))
        ? (constraintErrors.specialRateTokens = true)
        : (parsedSearchQueryParams.specialRateTokens = specialRateTokens);
    } else if (datesFlexParams.includes(param)) {
      const booleanParam = paramStringValue;
      const { booleanValue, error } = convertToBoolean(booleanParam?.toLowerCase().trim());
      !error
        ? (parsedSearchQueryParams.datesFlex = booleanValue)
        : (constraintErrors.datesFlex = true);
    } else if (booleanParams.includes(param)) {
      const booleanParam = paramStringValue;
      const { booleanValue, error } = convertToBoolean(booleanParam?.toLowerCase().trim());
      !error ? (parsedSearchQueryParams[param] = booleanValue) : (constraintErrors[param] = true);
    } else if (occpuancyParams.includes(param)) {
      (param === 'numRooms' && singleDigitRegex.test(paramStringValue)) ||
      (param === 'numAdults' && isValidAdults(paramStringValue)) ||
      (param === 'numChildren' && isValidChildren(paramStringValue))
        ? (parsedSearchQueryParams[param] = parseInt(paramStringValue))
        : (constraintErrors[param] = true);
    } else if (param === 'token') {
      const tokens = nextRouterUrlParamValue as string[] | string;
      if (Array.isArray(tokens)) {
        parsedSearchQueryParams.token = tokens.filter((token) => {
          if (validTokens.includes(token)) return true;
          constraintErrors.token = true;
          return false;
        });
      } else {
        validTokens.includes(tokens)
          ? (parsedSearchQueryParams.token = [tokens])
          : (constraintErrors.token = true);
      }
    } else if (rateParams.includes(param)) {
      buildTokenFromRateParams(param, paramStringValue, parsedSearchQueryParams);
    } else if (param === 'showBbox') {
      parsedSearchQueryParams.showBbox = paramStringValue === 'true';
    } else if (param === 'isDebugQuadTrees') {
      parsedSearchQueryParams.isDebugQuadTrees = paramStringValue === 'true';
    } else if (param === 'displayCurrency')
      parsedSearchQueryParams.displayCurrency = paramStringValue;
    else if (param === 'adjoiningRoomStay') {
      parsedSearchQueryParams.adjoiningRoomStay = paramStringValue === 'true';
    } else if (param === 'sortBy') {
      parsedSearchQueryParams.sortBy = paramStringValue as SortByDropDownValues;
    } else if (param === 'maxPoints') {
      if (onlyDigitsRegex.test(paramStringValue)) {
        const maxPoints = parseInt(paramStringValue);
        constraintErrors.maxPoints = maxPoints < MAX_POINTS_LOW_LIMIT;
        parsedSearchQueryParams.maxPoints = Number.isSafeInteger(maxPoints)
          ? constraintErrors.maxPoints
            ? MAX_POINTS_LOW_LIMIT
            : maxPoints
          : undefined;
      }
    } else if (filterParams.includes(param)) {
      if (param === 'f-amenityIds') {
        if (paramStringValue) {
          const amenityFilters = paramStringValue.split(',');
          parsedSearchQueryParams.activeFiltersState.amenityFilters = amenityFilters.filter(
            (a, i) => Boolean(a) && amenityFilters.indexOf(a) === i
          );
        }
      }
      if (param === 'f-attributeIds') {
        if (paramStringValue) {
          const attributeFilters = paramStringValue.split(',');
          parsedSearchQueryParams.activeFiltersState.attributeFilters = attributeFilters.filter(
            (a, i) => Boolean(a) && attributeFilters.indexOf(a) === i
          );
        }
      }
      if (param === 'f-brandCodes') {
        if (paramStringValue) {
          const brandFilters = paramStringValue.split(',');
          parsedSearchQueryParams.activeFiltersState.brandFilters = brandFilters.filter(
            (b, i) => Boolean(b) && brandFilters.indexOf(b) === i
          );
        }
      }
      if (param === 'f-price') {
        const range = paramStringValue.split(',');
        if (range.every((value) => onlyDigitsRegex.test(value))) {
          const priceFilter = range.map((val) => +val) as Tuple<2, number>;

          parsedSearchQueryParams.activeFiltersState.priceFilter =
            Number.isSafeInteger(priceFilter[0]) && Number.isSafeInteger(priceFilter[1])
              ? priceFilter
              : undefined;
        }
      }
      if (param === 'f-tripAdvisorRatings') {
        parsedSearchQueryParams.activeFiltersState.ratingsFilter =
          paramStringValue as TripAdvisorRatings;
      }
      if (param === 'availableHotelsOnly') {
        const booleanParam = paramStringValue;
        const { booleanValue, error } = convertToBoolean(booleanParam?.toLowerCase().trim());
        !error
          ? (parsedSearchQueryParams.activeFiltersState.showAvailableHotels = booleanValue)
          : (constraintErrors.showAvailableHotels = true);
      }
    } else if (param === 'placeId') {
      parsedSearchQueryParams.placeId = paramStringValue;
    } else if (param === 'geocodeType') {
      parsedSearchQueryParams.geocodeType = paramStringValue as GeocodePlaceType;
    } else if (param === 'coords') {
      const splitCoords = paramStringValue.split(',');
      if (splitCoords.length === 2) {
        const [latitude, longitude] = splitCoords;
        parsedSearchQueryParams.coords = {
          latitude: parseFloat(latitude ?? ''),
          longitude: parseFloat(longitude ?? ''),
        };
      }
    } else if (param === 'bounds') {
      const splitBounds = paramStringValue.split(',');
      if (splitBounds.length === 4) {
        const [southwestLatitude, southwestLongitude, northeastLatitude, northeastLongitude] =
          splitBounds;
        parsedSearchQueryParams.bounds = {
          northeast: {
            latitude: parseFloat(northeastLatitude ?? ''),
            longitude: parseFloat(northeastLongitude ?? ''),
          },
          southwest: {
            latitude: parseFloat(southwestLatitude ?? ''),
            longitude: parseFloat(southwestLongitude ?? ''),
          },
        };
      }
    } else if (param === 'cid') {
      parsedSearchQueryParams.cid = paramStringValue;
    } else if (param === 'content') {
      parsedSearchQueryParams.content = paramStringValue;
    }
  }

  const { arrivalYear, arrivalMonth, arrivalDay, departureYear, departureMonth, departureDay } =
    parsedSearchQueryParams;

  // fill in 3 part date strings if all 6 params were in URL
  if (
    arrivalYear &&
    arrivalMonth &&
    arrivalDay &&
    departureYear &&
    departureMonth &&
    departureDay
  ) {
    parsedSearchQueryParams.threePartFromDate = `${arrivalYear.padStart(
      2,
      '0'
    )}-${arrivalMonth.padStart(2, '0')}-${arrivalDay.padStart(2, '0')}`;
    parsedSearchQueryParams.threePartToDate = `${departureYear.padStart(
      2,
      '0'
    )}-${departureMonth.padStart(2, '0')}-${departureDay.padStart(2, '0')}`;
    parsedSearchQueryParams.arrivalDate = parsedSearchQueryParams.threePartFromDate;
    parsedSearchQueryParams.departureDate = parsedSearchQueryParams.threePartToDate;
  }

  // Check for arrivalDate and departureDate
  if (parsedSearchQueryParams.arrivalDate && parsedSearchQueryParams.departureDate) {
    validateAndFormatArrivalDepartureDates(parsedSearchQueryParams, constraintErrors);
    checkDayUseWithPoints(parsedSearchQueryParams, constraintErrors);
  }
  checkTMTPConstraints(parsedSearchQueryParams, constraintErrors);
  parseMultiRoom(parsedSearchQueryParams, nextRouterUrlParamsObject, constraintErrors);

  // If sessionToken was empty in the URL
  if (parsedSearchQueryParams.sessionToken === '') {
    parsedSearchQueryParams.sessionToken = uuidv4();
  }

  return {
    parsedSearchQueryParams,
    constraintErrors,
  };
};
