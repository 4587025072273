import type { TFunction } from 'i18next';

type SortByDropDownKeyValues = { [key in SortByDropDownValues]: SortByDropDownValues };
export type SortByDropDownValues =
  | 'POINTS_DESCENDING'
  | 'POINTS_ASCENDING'
  | 'PRICE_ASCENDING'
  | 'PRICE_DESCENDING'
  | 'RATINGS_ASCENDING'
  | 'RATINGS_DESCENDING'
  | 'DISTANCE'
  | 'BRAND';

export type SortByMetricsKeyValues = { [key in SortByDropDownValues]: SortByMetricsValues };
type SortByMetricsValues =
  | 'Price: high-low'
  | 'Price: low-high'
  | 'Distance'
  | 'Brand'
  | 'Points: high-low'
  | 'Points: low-high'
  | 'Ratings: high-low'
  | 'Ratings: low-high';

export const SORTBY_DROP_DOWN: SortByDropDownKeyValues = {
  DISTANCE: 'DISTANCE',
  POINTS_ASCENDING: 'POINTS_ASCENDING',
  POINTS_DESCENDING: 'POINTS_DESCENDING',
  PRICE_ASCENDING: 'PRICE_ASCENDING',
  PRICE_DESCENDING: 'PRICE_DESCENDING',
  RATINGS_ASCENDING: 'RATINGS_ASCENDING',
  RATINGS_DESCENDING: 'RATINGS_DESCENDING',
  BRAND: 'BRAND',
};

export type LabelValue = {
  label: string;
  value: keyof SortByDropDownKeyValues;
  testIdLabel?: string;
  isDisabled: boolean;
  isVisible?: boolean;
};

export const getSortByDropDownOptions = (t: TFunction<'sort-by-drop-down'>): LabelValue[] => [
  {
    label: t('DistanceOptionText'),
    value: SORTBY_DROP_DOWN.DISTANCE,
    testIdLabel: 'distanceSort',
    isDisabled: false,
    isVisible: true,
  },
  {
    label: t('ascendingRatePointsOptionText'),
    value: SORTBY_DROP_DOWN.POINTS_ASCENDING,
    testIdLabel: 'ascendingRatePointsSort',
    isDisabled: false,
    isVisible: true,
  },
  {
    label: t('descendingRatePointsOptionText'),
    value: SORTBY_DROP_DOWN.POINTS_DESCENDING,
    testIdLabel: 'descendingRatePointsSort',
    isDisabled: false,
    isVisible: true,
  },
  {
    label: t('ascendingRatePriceOptionText'),
    value: SORTBY_DROP_DOWN.PRICE_ASCENDING,
    testIdLabel: 'ascendingRatePriceSort',
    isDisabled: false,
    isVisible: true,
  },
  {
    label: t('descendingRatePriceOptionText'),
    value: SORTBY_DROP_DOWN.PRICE_DESCENDING,
    testIdLabel: 'descendingRatePriceSort',
    isDisabled: false,
    isVisible: true,
  },
  {
    label: t('ascendingRatingsOptionText'),
    value: SORTBY_DROP_DOWN.RATINGS_ASCENDING,
    testIdLabel: 'ascendingRatingsSort',
    isDisabled: false,
    isVisible: true,
  },
  {
    label: t('descendingRatingsOptionText'),
    value: SORTBY_DROP_DOWN.RATINGS_DESCENDING,
    testIdLabel: 'descendingRatingsSort',
    isDisabled: false,
    isVisible: true,
  },
];

const PRICE_SORT = [SORTBY_DROP_DOWN.PRICE_ASCENDING, SORTBY_DROP_DOWN.PRICE_DESCENDING];
const POINTS_SORT = [SORTBY_DROP_DOWN.POINTS_ASCENDING, SORTBY_DROP_DOWN.POINTS_DESCENDING];
const TARATINGS_SORT = [SORTBY_DROP_DOWN.RATINGS_ASCENDING, SORTBY_DROP_DOWN.RATINGS_DESCENDING];

export const isSortByDistance = (sortType: SortByDropDownValues | null) =>
  sortType === SORTBY_DROP_DOWN.DISTANCE;
export const isSortByPrice = (sortType: SortByDropDownValues | null) =>
  !!(sortType && PRICE_SORT.includes(sortType));
export const isSortByPoints = (sortType: SortByDropDownValues | null) =>
  !!(sortType && POINTS_SORT.includes(sortType));
export const isSortByTaRatings = (sortType: SortByDropDownValues | null) =>
  !!(sortType && TARATINGS_SORT.includes(sortType));

export const getSortByOnUsePointsChange = (
  sortType: SortByDropDownValues | null,
  isPoints: boolean
) => {
  let resetSortType = sortType;
  if (isPoints) {
    if (sortType === SORTBY_DROP_DOWN.PRICE_ASCENDING) {
      resetSortType = SORTBY_DROP_DOWN.POINTS_ASCENDING;
    } else if (sortType === SORTBY_DROP_DOWN.PRICE_DESCENDING) {
      resetSortType = SORTBY_DROP_DOWN.POINTS_DESCENDING;
    }
  } else {
    if (sortType === SORTBY_DROP_DOWN.POINTS_ASCENDING) {
      resetSortType = SORTBY_DROP_DOWN.PRICE_ASCENDING;
    } else if (sortType === SORTBY_DROP_DOWN.POINTS_DESCENDING) {
      resetSortType = SORTBY_DROP_DOWN.PRICE_DESCENDING;
    }
  }
  return resetSortType;
};

export const getSortType = (brandCode: string | null, sortBy: SortByDropDownValues | null) => {
  if (sortBy !== null) {
    return sortBy;
  } else {
    return SORTBY_DROP_DOWN.DISTANCE;
  }
};
