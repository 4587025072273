import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { returnInitialBounds } from '../components/map/map.utils';
import {
  useHotelSummaryOptions_GeocodePageQuery,
  useHotelSummaryOptions_GeocodePage_VariantQuery,
} from '../gql/queries';
import { useCoordsFromParams } from './use-coords-from-params';
import { useFeatureToggleConfig } from './use-feature-toggle';
import { useInLanguageAutocomplete } from './use-in-language-autocomplete';
import { keepPreviousData } from '@tanstack/react-query';
import { useLocation } from '@dx-ui/framework-location-provider';

export const useGetDreamPageData = (isEnabled = true) => {
  const router = useRouter();
  const locationPath = useMemo(() => {
    const routerPath = router.asPath?.split('?')[0];
    if (routerPath) return routerPath.replace(/\/$/, '').replace(/^\//, '');
    return '';
  }, [router.asPath]);
  const { enabled: aspectRatioImageEnabled } = useFeatureToggleConfig(
    'search-ui-aspect-ratios-images'
  );
  const { country: guestLocationCountry, isLoading: isGuestLocationLoading } = useLocation();
  const { isInLanguageAutocomplete, language } = useInLanguageAutocomplete();
  const { data, isLoading, isFetching } = useHotelSummaryOptions_GeocodePageQuery(
    {
      path: locationPath,
      language: isInLanguageAutocomplete && language ? language : 'en',
      queryLimit: 20,
      currencyCode: 'USD',
      titleFormat: 'md',
      input: { guestLocationCountry: guestLocationCountry || 'US' },
    },
    {
      enabled: aspectRatioImageEnabled && isEnabled && !isGuestLocationLoading,
      placeholderData: keepPreviousData,
    }
  );
  const {
    data: geocodeHSOVariantData,
    isLoading: isGeocodeHSOVariantLoading,
    isFetching: isGeocodeHSOVariantFetching,
  } = useHotelSummaryOptions_GeocodePage_VariantQuery(
    {
      path: locationPath,
      language: isInLanguageAutocomplete && language ? language : 'en',
      queryLimit: 20,
      currencyCode: 'USD',
      titleFormat: 'md',
      input: { guestLocationCountry: guestLocationCountry || 'US' },
    },
    {
      enabled: !aspectRatioImageEnabled && isEnabled && !isGuestLocationLoading,
      placeholderData: keepPreviousData,
    }
  );

  const { boundsFromParamCoords } = useCoordsFromParams();

  const getHSOData = useMemo(() => {
    const geocodeHSOData = aspectRatioImageEnabled ? data : geocodeHSOVariantData;
    const isDataLoading = aspectRatioImageEnabled ? isLoading : isGeocodeHSOVariantLoading;
    if (!isDataLoading && geocodeHSOData) {
      const pageData = geocodeHSOData?.geocodePage;
      const bounds = returnInitialBounds(
        pageData?.hotelSummaryOptions?.bounds,
        pageData?.match?.geometry?.bounds,
        boundsFromParamCoords
      );
      const isGlobalPage = !!pageData?.location?.globalBounds;
      //This is a bandaid. For macro pages w/ global bounds we have to throw away match name for now since the match name it returns will not lead to a valid OHW page.
      const matchName = !isGlobalPage ? pageData?.match?.name || '' : '';
      return {
        bounds,
        data: pageData,
        isLoading: aspectRatioImageEnabled ? isLoading : isGeocodeHSOVariantLoading,
        locationMatchName: matchName,
      };
    }
  }, [
    aspectRatioImageEnabled,
    boundsFromParamCoords,
    data,
    geocodeHSOVariantData,
    isGeocodeHSOVariantLoading,
    isLoading,
  ]);

  return {
    ...getHSOData,
    isLoading: getHSOData?.isLoading ?? false,
    locationPath,
    isFetching: aspectRatioImageEnabled ? isFetching : isGeocodeHSOVariantFetching,
  };
};
