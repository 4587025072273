//Agents
export const CHANGE_DEFAULT_SORT_WHEN_USING_POINTS = { agentId: 'a-tTCxboiLp1xh' };
export const RENOVATED_PROPERTY_CHIP = { agentId: 'a-1WB9qz7LcqNO' };
export const WHY_PROPERTY = { agentId: 'a-jJfo0QQjRpXD' };
export const BRAND_FILTER_CATEGORIES_V2 = { agentId: 'a-NHNLF37qpGEx' };
export const MOBILE_FRIENDLY_ENHANCEMENTS = { agentId: 'a-qYqoRvWGbg' };
export const BIGGER_MAP_CTA = { agentId: 'a-4bMj6h2x3Lth' }; // NHCSEARCH-5123
export const REMOVE_FLEXIBLE_DATES = { agentId: 'a-88XVLKrnNI' }; // NHCSEARCH-5088
export const DATELESS_ZOOM_LEVELS = { agentId: 'a-5KKVOezArpdx' }; // NHCSEARCH-5134
export const PRICE_FILTER_RADIO_BUTTONS = { agentId: 'a-E2PB4eih4U' }; // NHCSEARCH-5048
export const SUGGESTED_FILTER_PILLS = { agentId: 'a-fhZYsIABLO' }; //NHCSEARCH-5121
export const AMENITY_SECTION_RANKING = { agentId: 'a-jZIxq49KNw' }; //NHCSEARCH-5126
export const HONORS_LOGIN_BANNER = { agentId: 'a-g3S1ye7sGC' }; //NHCSEARCH-5265
export const HIGHEST_AMENITIES_SUGGESTED_FILTERS = { agentId: 'a-GmE1urgBDm' }; //NHCSEARCH-5346
export const COMPARE_PROPERTIES = { agentId: 'a-q0NNuVpax2' }; // NHCSEARCH-5249

export const VARIANTS = {
  Default: 'a',
  VariantB: 'b',
  VariantC: 'c',
  VariantD: 'd',
};

export const GOALS = {
  BookingComplete: 'booked-ok',
  FilterEngagement: 'g-filterengagement',
  FlexDatesPageLoad: 'g-4DgGm9x0WU',
  PaymentPageLoad: 'g-ezxfS9StvV',
  RoomsPageLoad: 'g-5ycTw2orHw',
  LocationsPageLoad: 'g-Bnktxc1kfY',
  SearchResultsPageLoad: 'g-0JJz7s9mtH',
  suggestedFilterEngagement: 'g-9GiRp71nci',

  ZeroResultsCount: 'g-zeroresultscount',

  //Default Locations card and reel engagements
  NearbyDestinationCardEngagement: 'g-ul1xthOX0f',
  PopularDestinationCardEngagement: 'g-aXdeDK6XHj',
  OverallDestinationCardEngagement: 'g-IWoGU9Qp7n',

  //NHCSEARCH-4697 Brand Categories
  BrandsFilterApply: 'g-rBf6w9ia4l',
  BrandsFilterClick: 'g-iVmMvcnCEJ',

  //NHCSEARCH-4783 Default locations Hero and Shop Form Update
  DLFindAHotel: 'g-34ZXagz3zu',

  // NHCSEARCH-5028 Why Property (reused from 4679 Why Brand)
  whyBrandClicked: 'g-PxxunyVcGw',
  whyBrandTotalClicks: 'g-VmdFNCNK8X',

  // NHCSEARCH-5064 | Mobile friendly enhancements
  updatedLocationsSearch: 'g-AWonhA6KDS',
  filterModalsEngagement: 'g-pz1vhJ7uRB',

  //NHCSEARCH-5123 Bigger Map CTA
  MapCTAClick: 'g-pF9mNUX7G2',

  //NHCSEARCH-5088 Remove Flexible dates
  myFlexibleDatesClick: 'g-lNDlYVzRu9',
  resetDates: 'g-gjmxUjWSsN',

  // NHCSEARCH-5048
  priceFilterSelected: 'g-433B3CJXt3',

  //NHCSEARCH-5121
  amenitiesFilterApplied: 'g-1xsItW1p6l',

  //NHCSEARCH-5249
  compareHotelsCheckboxSelected: 'g-tJK6XYgfzg',
  compareHotelsCTAClick: 'g-fLXN7qrWyE',
  compareHotelsToggleOn: 'g-6JoBhxn0qO',
};
