import { useMemo } from 'react';
import { useWrappedRouter } from './use-wrapped-router';
import { getBoundsAroundCoords } from '../utils/get-bounds-around-coords';
import { useAirportsQuery } from '../gql/queries';
import type { NextRouter } from 'next/router';
import type { GeocodePlaceType } from '@dx-ui/gql-types';
import { useGetMVTSelections } from './use-get-mvt-selections';
import { DATELESS_ZOOM_LEVELS } from '../constants';

const geocodeTypeDistanceMap: { [key in GeocodePlaceType]?: number } = Object.freeze({
  property: 40,
  address: 40,
  airport: 40,
  pointOfInterest: 40,
});

const getLocationUri = (router: NextRouter, shouldCheckForLocationPage: boolean) => {
  if (!shouldCheckForLocationPage || !router.isReady) {
    return '';
  }
  const { slug } = router.query;
  return Array.isArray(slug) ? `/locations/${slug.join('/')}/` : '';
};

export const useCoordsFromParams = () => {
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();

  const geocodeType = queryParameters?.geocodeType || '';

  const isMVTGeocodeType = useMemo(
    () => Object.keys(geocodeTypeDistanceMap).includes(geocodeType),
    [geocodeType]
  );

  const { isMVTVariant } = useGetMVTSelections({
    agentId: DATELESS_ZOOM_LEVELS.agentId,
    isProvisional: isMVTGeocodeType,
    MVTVariants: ['b', 'c', 'd'],
  });
  const getMVTZoomLevel = (MVTVariants: Record<string, boolean>) => {
    switch (true) {
      case MVTVariants.b:
        return 5;
      case MVTVariants.c:
        return 15;
      case MVTVariants.d:
        return 25;
      default:
        return null;
    }
  };

  const MVTZoomLevel = getMVTZoomLevel(isMVTVariant);
  const paramCoords = queryParameters?.coords;
  const shouldCheckForLocationPage = geocodeType === 'airport';
  const { data: airportsData } = useAirportsQuery(
    {
      language: router?.locale ?? 'en',
      hasLocationPage: true,
      locationPageUri: getLocationUri(router, shouldCheckForLocationPage),
    },
    { enabled: !!router.isReady && !!shouldCheckForLocationPage }
  );

  const shouldIgnoreParamCoords =
    (shouldCheckForLocationPage && !airportsData) ||
    (Array.isArray(airportsData?.airports) && airportsData.airports.length > 0) ||
    !Object.hasOwnProperty.call(geocodeTypeDistanceMap, geocodeType);

  const isMVTZoomLevelVariant = !!MVTZoomLevel && isMVTGeocodeType && !shouldIgnoreParamCoords;

  const result = useMemo(() => {
    if (geocodeType && paramCoords && typeof window !== 'undefined' && !shouldIgnoreParamCoords) {
      const miles = isMVTZoomLevelVariant
        ? MVTZoomLevel
        : geocodeTypeDistanceMap[geocodeType] || 40;

      return {
        boundsFromParamCoords: getBoundsAroundCoords(paramCoords, miles),
        shouldUseParamCoords: true,
      };
    }
    return { boundsFromParamCoords: null, shouldUseParamCoords: false };
  }, [MVTZoomLevel, geocodeType, isMVTZoomLevelVariant, paramCoords, shouldIgnoreParamCoords]);

  return result;
};
