import { useEffect, useState, useMemo } from 'react';
import { publicRuntimeConfig } from '../constants';
import { getCookie } from '@dx-ui/utilities-cookies';

// cookie name for the real Adobe Audience Manager cookie
const TMS_LIVE_COOKIE_NAME = 'TMS';
// in lower environments a dev cookie can be used since Adobe launch script overwrites any changes on page load, which makes testing challenging
const TMS_DEV_COOKIE_NAME = 'TMS_DEV';

const getSegmentCookie = () => {
  let cookie = '';
  // for lower environments check if we have a dev cookie & use if it exists
  if (publicRuntimeConfig.APP_ENV !== 'prd') {
    cookie = getCookie(TMS_DEV_COOKIE_NAME);
  }
  if (!cookie) {
    cookie = getCookie(TMS_LIVE_COOKIE_NAME);
  }

  return cookie;
};

const getUserSegments = (hasMounted: boolean) => {
  const cookie = hasMounted ? getSegmentCookie() : '';
  return getUserSegmentsFromCookie(cookie);
};

const getUserSegmentsFromCookie = (cookie: string) =>
  cookie
    ?.split(',')
    .filter((entry) => (entry.toLowerCase() || '').startsWith('web'))
    .map((entry) => entry.split('=')[1] || '');

export const useSegments = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return useMemo(() => getUserSegments(hasMounted), [hasMounted]);
};
