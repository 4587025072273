import { publicRuntimeConfig } from '../constants';
/**
 *
 * @param url
 * @returns if app is either a) in local b) in RAE env it returns a public asset directory as is. Otherwise it adds app prefix for test env onward
 */
export const prefixPublicAssetUriWithAppPrefix = (url: string) =>
  publicRuntimeConfig.NODE_ENV === 'production' && publicRuntimeConfig.IS_RAE_ENV !== 'true'
    ? url.replace('/assets', '/dx_shop_search_app/assets')
    : url;
