import { useSMBContextLocalStorage } from '@dx-ui/utilities-use-smb-context-local-storage';
import { useWrappedRouter } from './use-wrapped-router';
import { useEffect, useRef } from 'react';
import { publicRuntimeConfig } from '../constants';
import { useFeatureToggleConfig } from './use-feature-toggle';

/**
 * If user deeplinks in with smbRate set to true but context was personal this hook updates context to business only after initial url params are parsed
 */
export const useInitializeSMBContextToSMBRate = () => {
  const { enabled: isSMBContextSwitchingEnabled } = useFeatureToggleConfig('WEBPLAT-551');
  const { setSMBContext } = useSMBContextLocalStorage(isSMBContextSwitchingEnabled);
  const { router, safeQueryParams } = useWrappedRouter();
  const hasEvaluatedSMBContextAfterUrlChange = useRef(false);

  useEffect(() => {
    if (router.isReady) {
      if (
        isSMBContextSwitchingEnabled &&
        Boolean(safeQueryParams?.smbRate) &&
        !hasEvaluatedSMBContextAfterUrlChange.current
      ) {
        setSMBContext('business');
      }
      hasEvaluatedSMBContextAfterUrlChange.current = true;
    }

    const handleRouteChange = (urlStr: string) => {
      const parsedUrl = new URL(
        `${(publicRuntimeConfig.OHW_BASE_URL as string).slice(
          0,
          publicRuntimeConfig.OHW_BASE_URL.length - 1
        )}${urlStr}`
      );
      const urlParams = new URLSearchParams(parsedUrl.search);
      if (isSMBContextSwitchingEnabled && urlParams.get('smbRate')) {
        setSMBContext('business');
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    isSMBContextSwitchingEnabled,
    router.events,
    router.isReady,
    safeQueryParams?.smbRate,
    setSMBContext,
  ]);
};
