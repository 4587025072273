import { createContext } from 'react';
import { createMetrics } from '@dx-ui/config-metrics';
import { digitalData } from './constants';
import { publicRuntimeConfig } from '../constants';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import ExtendedAdobeTagManager from './extended-metrics';

const tagManager = new ExtendedAdobeTagManager({
  debug: true,
  digitalData,
  seedFile: publicRuntimeConfig.TRACKING_SEED_FILE,
  server: !isBrowser,
});

const shopMetricConfig = {
  vendors: [
    {
      name: 'Adobe Tag Manager',
      api: tagManager,
    },
  ],
  debug: true,
};

type FunctionKeys<T> = {
  [K in keyof T]: T[K] extends (...args: unknown[]) => unknown ? K : never;
}[keyof T];
// react-metrics requires parameters in array - a silly gotcha
// this forEach simplifies the api to accept expected parameters
const metricsAPI: ExtendedAdobeTagManager =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  createMetrics<ExtendedAdobeTagManager>(shopMetricConfig).api;
const simplifiedApi: Partial<
  Record<FunctionKeys<ExtendedAdobeTagManager>, (...props: unknown[]) => unknown>
> = {};
Object.keys(metricsAPI).forEach((key) => {
  const functionKey = key as FunctionKeys<ExtendedAdobeTagManager>;
  simplifiedApi[functionKey] = (...props: unknown[]) => metricsAPI[functionKey](props);
});

export const MetricsContext = createContext<ExtendedAdobeTagManager>(
  simplifiedApi as ExtendedAdobeTagManager
);
