/* eslint-disable no-underscore-dangle */
import { AMENITY_CODES, PAGES, SORTBY_KEYS } from './constants';
import AdobeTagManager from './adobe-tag-manager';

import type {
  ISearchPageDataProps,
  IPaginationProps,
  IPropertyDetailClickProps,
  IErrorPageDataProps,
  IBrandDetailProps,
  IPaginationDataProps,
  IBrandSearchProps,
  ISearchResultPageDataProps,
  SetSearchZeroPageMetricsDataProps,
  IPageConfigProps,
  DreamPageDefaultProps,
  IPageDataProps,
  ICurrencyConverterProps,
  FeaturedLocationsProps,
  SetLocationSearchNameProps,
  DreamPageDataProps,
  TransactionPropertyData,
  UserDataProps,
  ICreateSearchChangesString,
} from './types';

import {
  createPropertySearchCriteriaString,
  createPropertySearchRefinementsString,
  createPropertySearchRateCodeString,
  createPropertySearchBrandResultsString,
  createPropertySearchDateInfoString,
  createSearchChangesString,
  getSearchPageName,
  getLocationPageName,
  getGoUserType,
  getPropertySearchDetails,
  getPointsExplorerFilterString,
} from './utils';
import { publicRuntimeConfig } from '../constants';
import Router from 'next/router';
import type { ActiveFiltersState, FilterModalType } from '../components/filters/filter.constants';
import { createPropertySearchDetailsString } from '@dx-ui/config-metrics';
import { HotelAttributeIds } from '../components/filters/filter.constants';

const {
  ADOBE_SEARCH_PAGE,
  SEARCH_PAGE,
  ADOBE_LOCATIONS_PAGE_HOME,
  ADOBE_LOCATIONS_NEAR_ME,
  DEFAULT_LOCATIONS_PAGE,
  ADOBE_POINTS_EXPLORER,
} = PAGES;

export default class ExtendedAdobeTagManager extends AdobeTagManager {
  searchPropertySearchFilters(
    selectedFilters: string,
    priceSilderValue?: string,
    selectedTripAdvisorFilter?: string,
    filterType?: FilterModalType
  ) {
    this._resetEvents();
    this._set('page.attributes.propertySearchFilters', selectedFilters);
    if (priceSilderValue) this._set('page.attributes.priceSliderValue', priceSilderValue);
    const selectedFilterList = selectedFilters
      .split(':')
      ?.map(
        (amenityId) =>
          Object.entries(AMENITY_CODES).find((amenity) => amenity[0] === amenityId)?.[1] ||
          amenityId
      )
      ?.join('|');
    if (selectedFilterList && filterType !== 'BRANDS' && filterType !== 'PRICE')
      this._set('page.attributes.propertySearchDetails', selectedFilterList);
    if (selectedTripAdvisorFilter) {
      const propertySearchDetails = this.digitalData.page.attributes.propertySearchDetails;
      this._set(
        'page.attributes.propertySearchDetails',
        getPropertySearchDetails(propertySearchDetails, selectedTripAdvisorFilter)
      );
    }
    void this.track('searchPropertySearchFilters', null, true);
  }

  searchPricing(pricingData: TransactionPropertyData[]) {
    this._resetEvents();
    this._set('transaction.properties', pricingData);
    void this.track('searchPricing', null, true);
  }
  setPriceClicked(pricingData: TransactionPropertyData) {
    this._resetEvents();
    this._set('transaction.priceClicked', pricingData);
  }

  trackFilterReset() {
    this._resetEvents();
    this._set('event[0].eventInfo.eventAction', 'searchDreamButtonClick');
    this._set('page.attributes.actionDetail', 'Button:ResetFilters:SearchandDream');
    this._set('page.attributes.propertySearchFilters', '');
    this._set('page.attributes.propertySearchDetails', '');
    void this.track('searchPropertySearchFilters', null, true);
  }

  propertySearchSort(sortBy: string) {
    this._resetEvents();
    this._set(
      'page.attributes.propertySearchSort',
      SORTBY_KEYS[sortBy as keyof typeof SORTBY_KEYS]
    );
    void this.track('searchPropertySearchSortOrder', null, true);
  }

  setSearchPageData({
    country,
    state,
    city,
    brandCode,
    brandName,
    page,
    totalNumSearchResults,
    analyticsSpecialRates,
    numRooms,
    numAdults,
    numChildren,
    arrivalDate,
    departureDate,
    location,
    datesFlex: isFlexDatesEnabled,
    distance,
    brands,
    isUserCookiedOrLoggedIn,
    tier,
    tierName,
    points,
    goUserType,
    promoCode = '',
    groupCode = '',
    corporateCode = '',
    hhonorsNumber,
    guestId,
    userCountry,
    sortOrder,
    searchType,
    currencyCode = '',
    isCBP = false,
    smbContext,
  }: ISearchPageDataProps) {
    const {
      isAarp = false,
      isGovernment = false,
      isSenior = false,
      isTravelAgents = false,
      isTripleA = false,
      isUsePoints = false,
      isEmployee = false,
      isOwner = false,
      isFriendsAndFamily = false,
      isHGVOwner = false,
      isSMB = false,
    } = analyticsSpecialRates;

    // was any special rate used? Needed for adobe analytics
    const isSpecialRatesUsed =
      Object.values(analyticsSpecialRates).some(Boolean) ||
      !!promoCode ||
      !!groupCode ||
      !!corporateCode;
    const filterType = 'ALL';

    const pageName = getSearchPageName(brandName);

    // page.attributes
    if (country) this._set('page.attributes.categorySearchCountry', country || '');
    else this._set('page.attributes.categorySearchState', state || '');
    this._set('page.attributes.city', city || '');
    this._set('page.attributes.contentTypes', 'Images,Text');

    // page.search
    this._set('page.attributes.propertySearchResults', totalNumSearchResults || 0);
    this._set(
      'page.attributes.propertySearchDateInfo',
      createPropertySearchDateInfoString({
        arrivalDate: arrivalDate || '',
        departureDate: departureDate || '',
      })
    );
    this._set('page.attributes.siteExperience', 'R');
    this._set('page.attributes.expType', 'New Hilton');
    this._set('page.attributes.propertySearchType', searchType);
    this._set(
      'page.attributes.propertySearchSort',
      SORTBY_KEYS[sortOrder as keyof typeof SORTBY_KEYS]
    );
    this._set(
      'page.attributes.propertySearchRateCode',
      createPropertySearchRateCodeString({
        promoCode,
        groupCode,
        corporateCode,
        isTravelAgents,
        isTripleA,
        isAarp,
        isSenior,
        isGovernment,
        isUsePoints,
        isEmployee,
        isOwner,
        isFriendsAndFamily,
        isHGVOwner,
      })
    );
    this._set(
      'page.attributes.propertySearchRefinements',
      createPropertySearchRefinementsString({
        filterType,
        brands: brands || [],
        distance: distance || 0,
      })
    );
    this._set(
      'page.attributes.propertySearchCriteria',
      createPropertySearchCriteriaString({
        numRooms: numRooms || 1,
        numAdults: numAdults || 1,
        numChildren: numChildren || 0,
        isFlexDatesEnabled: isFlexDatesEnabled || false,
        isUsePoints,
        isSpecialRatesUsed,
        distance: distance || 0,
      })
    );
    this._set(
      'page.attributes.propertySearchDetails',
      createPropertySearchDetailsString({
        numRooms: numRooms || 1,
        numAdults: numAdults || 1,
        numChildren: numChildren || 0,
        currencyCode,
        datesFlex: isFlexDatesEnabled || false,
        corporateCode,
        groupCode,
        isAarp,
        isGovernment,
        isSenior,
        isTravelAgents,
        isTripleA,
        isUsePoints,
        isEmployee,
        isOwner,
        isFriendsAndFamily,
        isHGVOwner,
        isCBP,
        isSMB,
        promoCode,
      })
    );
    this._set('page.attributes.propertySearchCountry', country || '');
    this._set(
      'page.attributes.propertySearchBrandResults',
      createPropertySearchBrandResultsString({
        brands: brands || [],
        totalNumSearchResults: totalNumSearchResults || 0,
      })
    );
    this._set('page.attributes.funnelType', 'Main');
    this._set('page.attributes.propertySearchLocation', location || '');
    this._set(
      'page.attributes.dialAudience',
      isUserCookiedOrLoggedIn ? 'searchOhwHonors.test' : 'searchOhwNonHonors.test'
    );
    this._set('page.attributes.version', publicRuntimeConfig.APP_VER);

    // page.pageInfo
    this._set('page.pageInfo.pageTitle', document.title);
    this._set('page.pageInfo.destinationURL', new URL(document.location.href).toString());
    this._set('page.pageInfo.language', Router.locale as string);
    this._set('page.pageInfo.pageName', pageName || '');
    this._set('page.pageInfo.pageType', page || '');
    this._set('page.pageInfo.pageTitle', `${ADOBE_SEARCH_PAGE} Results - Hilton`);
    this._set('page.pageInfo.pageType', `${ADOBE_SEARCH_PAGE} Results`);
    this._set('page.pageInfo.pageDetail1', '');
    this._set('page.pageInfo.pageDetail2', '');
    this._set('page.pageInfo.pageDetail3', '');

    // page.category
    this._set('page.category.brand', brandCode);
    this._set('page.category.exp', 'Browser');
    this._set('page.category.primaryCategory', ADOBE_SEARCH_PAGE);
    this._set('page.category.siteName', brandName || '');
    this._set('page.category.siteType', 'B');

    // product this needs to be set empty initially
    this._set('product[0].productInfo.productID', '');

    // user data
    this.setUserData({
      honorsNumber: hhonorsNumber,
      points,
      tierName,
      goUserType,
      country: userCountry,
      smbContext,
    });

    // conductrics data
    this.digitalData = this.digitalData || {};
    this.digitalData.conductrics_traits = this.digitalData.conductrics_traits || [];
    if (guestId && guestId > 0) {
      this.digitalData.conductrics_traits.push('login:yes');
      this.digitalData.conductrics_traits.push(`tier:${tier}`);
    } else {
      this.digitalData.conductrics_traits.push('login:no');
    }
  }

  setUserData({ honorsNumber, points, tierName, goUserType, country, smbContext }: UserDataProps) {
    const isLoggedIn = honorsNumber && +honorsNumber;
    this._set('user[0].profile[0].profileInfo.profileID', isLoggedIn ? +honorsNumber : '');
    this._set(
      'user[0].profile[0].profileInfo.pointsBalance',
      isLoggedIn ? (points ? points : 0) : ''
    );
    this._set('user[0].profile[0].profileInfo.rewardsTier', tierName || '');
    this._set('user[0].profile[0].attributes.goUserType', goUserType || '');
    this._set('user[0].profile[0].attributes.loginStatus', isLoggedIn ? 'Logged-in' : 'Logged-out');
    country && this._set('user[0].country', country);
    if (smbContext) {
      this._set(
        'user[0].profile[0].attributes.profileStatus',
        smbContext === 'business' ? 'business_travel' : smbContext
      );
    }
  }

  setOfferIdData(offerId?: number, offerName?: string) {
    if (offerId && offerName)
      this._set('page.attributes.offerid', `${offerId?.toString()}:${offerName}`);
  }

  setDreamPageData(props: DreamPageDataProps) {
    const {
      pageTitle,
      country,
      state,
      city,
      brandName,
      brandCode,
      categoryName,
      location,
      honorsNumber,
      tier,
      points,
      packages,
      currencyCode = '',
      isUsePoints,
      sortBy = 'DISTANCE',
      totalSize,
    } = props;
    const page = 'Locations';
    const pageName = getLocationPageName({
      brandName,
      categoryName,
      page: page as string,
      country,
      state,
      city,
      type: location?.type,
      typeName: location?.typeName,
    });

    // page.attributes
    if (country) this._set('page.attributes.categorySearchCountry', country);
    else this._set('page.attributes.categorySearchState', state);
    this._set('page.attributes.city', city);
    this._set('page.attributes.contentTypes', 'images,text');
    this._set('page.attributes.version', publicRuntimeConfig.APP_VER);
    this._set('page.attributes.siteExperience', 'R');
    this._set(
      'page.attributes.propertySearchDetails',
      `CC:${currencyCode || 'LOCAL'}${isUsePoints ? '|PT' : ''}`
    );
    this._set('page.attributes.propertySearchResults', totalSize);
    this._set(
      'page.attributes.propertySearchSort',
      SORTBY_KEYS[sortBy as keyof typeof SORTBY_KEYS]
    );

    // page.category
    this._set('page.category.brand', brandCode || 'Multibrand');
    this._set('page.category.exp', 'Browser');
    this._set(
      'page.category.primaryCategory',
      brandCode && !categoryName ? 'Brand' : 'Category Page'
    );

    // page.pageInfo
    this._set('page.pageInfo.pageTitle', pageTitle);
    this._set('page.pageInfo.destinationURL', new URL(document.location.href).toString());
    this._set('page.pageInfo.language', Router.locale as string);
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageType', page || '');
    this._set('page.pageInfo.pageDetail1', categoryName || '');
    this._set(
      'page.pageInfo.pageDetail2',
      country && state ? `${country}|${state}` : country ? country : ''
    );
    this._set('page.pageInfo.pageDetail3', city);

    // product this needs to be set empty initially
    this._set('product[0].productInfo.productID', '');
    // user[0].profile[0] values needs to be set empty initially
    this.setUserData({
      honorsNumber,
      points,
      tierName: tier,
      goUserType: getGoUserType(packages),
      country,
    });

    this._set('tmp.eventList', 'categoryPageView');
  }

  // page data
  setPageData({ configData, configRulesData, page }: IPageDataProps) {
    this._resetEvents();
    this.setConfigMetricsData({ configData, configRulesData });
    void this.track(page, null, true);
  }

  setZeroPageData({ configData, configRulesData }: IPageConfigProps) {
    this._resetEvents();
    this.setConfigMetricsData({ configData, configRulesData });
    void this.track('searchSearchZeroPageView', null, true);
  }

  setErrorPageData({ brandCode, errorCode, errorName }: IErrorPageDataProps) {
    this._resetEvents();
    this._set('event[0].eventInfo.eventAction', 'error');
    this._set('page.category.primaryCategory', 'error');
    this._set('page.category.subSection', `error:${errorName || ''}`);
    this._set('page.pageInfo.error', errorCode || '');
    this._set('page.pageInfo.pageName', `${brandCode || ''}:error:${errorName}`);
    void this.track('errorPageView', null, false);
    void this.track('errorEvent', null, false);
  }

  setBrandDetail({ ctyhocn, view, name, index }: IBrandDetailProps) {
    this._set('page.attributes.productFindingMethod', view); // Map or List
    this._set('product[0].productInfo.productID', ctyhocn);
    this._set('page.pageInfo.gridTileName', name);
    this._set('page.pageInfo.gridPosition', index);
    void this.track('categoryDetail', null, true);
  }

  setBrandSearch({ searchLocation }: IBrandSearchProps) {
    this._set('page.attributes.propertySearchLocation', searchLocation);
    void this.track('categorySearchPageView', null, true);
  }

  setSearchResultPageData({ brandName }: ISearchResultPageDataProps) {
    this._set('page.category.subSection', `${SEARCH_PAGE}:findhotels`);
    this._set('page.category.subSubSection', `${SEARCH_PAGE}:findhotels:results`);
    this._set(
      'page.pageInfo.pageName',
      `Browser:${Router.locale?.toUpperCase()}:${brandName.replace(
        /\s/g,
        ''
      )}:${ADOBE_SEARCH_PAGE}:${ADOBE_SEARCH_PAGE} Results`
    );
  }

  setPaginationData({
    totalPages,
    propertyResultList,
    propertyResults,
    brandedHotels,
  }: IPaginationDataProps) {
    this._set('page.attributes.propertyResultsPagination', totalPages);
    this._set('page.attributes.propertyResults', propertyResults);
    this._set('page.attributes.propertySearchResultList', propertyResultList);
    if (brandedHotels) {
      this._set(
        'page.attributes.propertySearchBrandResults',
        createPropertySearchBrandResultsString({
          brands: brandedHotels,
          totalNumSearchResults: propertyResults,
        })
      );
    }
    void this.track('numberOfPagesReturned', null, true);
  }

  // details
  trackPropertyDetailClick({ action }: IPropertyDetailClickProps) {
    this._set('page.attributes.propertyDetailAction', action);
    return this.track('searchAction', null, true);
  }

  // pagination
  trackPaginationClick({ paginationNumber, actionType }: IPaginationProps) {
    this._set('page.attributes.propertyPaginationNumber', paginationNumber);
    return this.track(actionType, null, true);
  }

  // Use points checkbox
  trackUsePointsChecked() {
    this._set('page.attributes.actionDetail', 'Search:UsePointsCheckbox');
    this._set('event[0].eventInfo.eventAction', 'searchUsePoints');
    return this.track('searchPropertySearchFilters', null, true);
  }

  // Currency converter
  trackCurrencyConverterClick({ currencyCode }: ICurrencyConverterProps) {
    this._set('page.attributes.currencyCode', currencyCode || 'LOCAL');
    this._set('page.attributes.actionDetail', 'Dropdown:SearchDream:CurrencyChanged');
    return this.track('searchPropertySearchFilters', null, true);
  }

  // Filter pill tracking
  trackFilterPillRemoved(filterId?: string) {
    this._set('page.attributes.actionDetail', 'Button:SearchDream:Filterdeselected');
    if (filterId === HotelAttributeIds.sale)
      this._set('page.attributes.propertySearchDetails', 'SALE');

    return this.track('searchPricing', null, true);
  }
  //suggested filters
  trackSuggestedFilters(amenityId: string) {
    const isSaleFilter = amenityId === HotelAttributeIds.sale;
    const amenityCode = isSaleFilter
      ? 'SALE'
      : Object.entries(AMENITY_CODES).find((amenity) => amenity[0] === amenityId)?.[1] || '';
    this._set('page.attributes.actionDetail', 'Search:SearchResults:SuggestedFilters');
    this._set('page.attributes.propertySearchDetails', amenityCode);
    return this.track('searchPropertySearchFilters', null, true);
  }
  //sales filter
  trackSalesFilter() {
    this._set('page.attributes.actionDetail', 'Search:SearchResults:SuggestedFilters');

    this._set('page.attributes.propertySearchDetails', 'SALE');
    return this.track('searchPropertySearchFilters', null, true);
  }
  //brand filter
  trackBrandFilterModalOpen() {
    this._set('page.attributes.actionDetail', 'hotel_brands');
    return this.track('searchPropertySearchFilters', null, true);
  }
  trackBrandFilterApply(appliedBrands: string[]) {
    this._set('page.attributes.actionDetail', 'hotel_brands_selected');
    this._set('page.attributes.propertySearchDetails', appliedBrands.join('|'));
    return this.track('searchPropertySearchFilters', null, true);
  }
  //price filter
  trackPriceFilterModalOpen() {
    this._set('page.attributes.actionDetail', 'price_filter');
    return this.track('searchPropertySearchFilters', null, true);
  }
  trackPriceFilterApply(appliedPrice: number) {
    this._set('page.attributes.actionDetail', 'price_filter_selected');
    this._set('page.attributes.propertySearchDetails', `0:${appliedPrice}`);
    return this.track('searchPropertySearchFilters', null, true);
  }
  // Signout
  trackSignoutClick() {
    this._resetEvents();
    this._set('user[0].profile[0].profileInfo.profileID', '');
    this._set('user[0].profile[0].profileInfo.pointsBalance', '');
    this._set('user[0].profile[0].profileInfo.rewardsTier', '');
    this._set('user[0].profile[0].attributes.goUserType', '');
    this._set('user[0].profile[0].attributes.loginStatus', 'Logged-out');
  }

  trackSearchPageWidgetSubmission({
    location,
    dates,
    rates,
    rooms,
    uriParams,
    prevAddress,
  }: ICreateSearchChangesString) {
    this._set(
      'page.attributes.searchChanges',
      createSearchChangesString({ location, prevAddress, dates, rates, rooms, uriParams })
    );
    void this.track('searchAction', null, true);
  }

  setSearchZeroPageMetricsData({
    brandCode,
    brandName,
    country,
    honorsNumber,
    points,
    tierName,
    packages,
    smbContext,
  }: SetSearchZeroPageMetricsDataProps & UserDataProps) {
    this._set('page.attributes.siteExperience', 'R');
    this._set('page.attributes.expType', 'New Hilton');
    this._set('page.pageInfo.pageName', `Browser:${Router.locale}:${brandCode}:Search:Search Zero`);
    this._set('page.category.exp', 'Browser');
    this._set('page.pageInfo.language', Router.locale as string);
    this._set('page.category.brand', brandCode || '');
    this._set('page.category.primaryCategory', ADOBE_SEARCH_PAGE);
    this._set('page.category.siteName', brandName || '');
    this._set('page.category.siteType', 'B');
    this._set('page.category.subSection', `${SEARCH_PAGE}:findhotels`);
    this._set('page.category.subSubSection', `${SEARCH_PAGE}:findhotels:index`);
    this._set('page.pageInfo.pageType', 'Search Zero');
    this._set('page.pageInfo.pageDetail1', '');
    this._set('page.pageInfo.pageDetail2', '');
    this._set('page.pageInfo.pageDetail3', '');
    this._set('page.pageInfo.pageTitle', document.title);
    this._set('page.pageInfo.destinationURL', new URL(document.location.href).toString());
    country && this._set('user[0].country', country);

    // user details
    this.setUserData({
      honorsNumber,
      points,
      tierName,
      goUserType: packages ? getGoUserType(packages) : undefined,
      country,
      smbContext,
    });
  }
  //points explorer
  setPointsExplorerPageMetricsData(brandName: string | undefined) {
    this._set(
      'page.pageInfo.pageName',
      `Browser:${Router.locale?.toUpperCase()}:${brandName}:CategoryPage:${ADOBE_POINTS_EXPLORER}`
    );
    this._set('page.category.exp', 'Browser');
    this._set('page.pageInfo.language', Router.locale as string);
    this._set('page.category.brand', brandName);
    this._set('page.category.siteName', brandName || '');
    this._set('page.category.primaryCategory', 'Category Page');
    this._set('page.pageInfo.pageType', 'Points Explorer');
    void this.track('categoryPageView', null, true);
  }
  setActivePointsExplorerFilters(activeFiltersState: ActiveFiltersState) {
    this._set(
      'page.attributes.propertySearchFilters',
      getPointsExplorerFilterString(activeFiltersState)
    );
    void this.track('categoryPageView', null, true);
  }

  srpEngagement(srp: string) {
    this._set('page.attributes.srpEngagement', srp || '');
    this._set('event[0].eventInfo.eventAction', 'searchSrpEngagement');
    return this.track('searchSrpEngagement', null, true);
  }

  setConfigMetricsData({ configData, configRulesData }: IPageConfigProps) {
    configData && this._set('page.attributes.configurationFeatures', JSON.stringify(configData));
    configRulesData &&
      this._set('page.attributes.configurationRule', JSON.stringify(configRulesData));
  }

  // DEFAULT LOCATIONS PAGE METRICS
  // - - - - - - - - - - - - - - - -
  setDefaultLocationsPageData({
    honorsNumber,
    points,
    tier,
    packages,
    country,
  }: DreamPageDefaultProps) {
    // page.attributes
    this._set('page.attributes.contentTypes', 'images,text');
    this._set('page.attributes.version', publicRuntimeConfig.APP_VER);

    // page.category
    this._set('page.category.brand', 'Multibrand');
    this._set('page.category.exp', 'Browser');
    this._set('page.category.primaryCategory', 'Category Page');

    // page.pageInfo
    const pageName = getLocationPageName({
      brandName: '',
      page: ADOBE_LOCATIONS_PAGE_HOME,
      country: '',
      state: '',
      city: '',
    });

    this._set('page.pageInfo.pageTitle', document.title);
    this._set('page.pageInfo.destinationURL', new URL(document.location.href).toString());
    this._set('page.pageInfo.language', Router.locale as string);
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageType', ADOBE_LOCATIONS_PAGE_HOME);
    this._set('page.pageInfo.pageDetail1', '');
    this._set('page.pageInfo.pageDetail2', '');
    this._set('page.pageInfo.pageDetail3', '');

    // product - this needs to be set empty initially
    this._set('product[0].productInfo.productID', '');
    this.setUserData({
      honorsNumber,
      points,
      tierName: tier,
      goUserType: packages ? getGoUserType(packages) : undefined,
      country,
    });
  }
  // LOCATIONS - HOTELS NEAR ME PAGE METRICS
  // - - - - - - - - - - - - - - - -
  setHotelNearMePageData({ honorsNumber, points, tier, packages, country }: DreamPageDefaultProps) {
    // page.attributes
    this._set('page.attributes.contentTypes', 'images,text');
    this._set('page.attributes.version', publicRuntimeConfig.APP_VER);

    // page.category
    this._set('page.category.brand', 'Multibrand');
    this._set('page.category.exp', 'Browser');
    this._set('page.category.primaryCategory', 'Category Page');

    // page.pageInfo
    const pageName = getLocationPageName({
      brandName: '',
      page: DEFAULT_LOCATIONS_PAGE,
      country: '',
      state: '',
      city: '',
      type: 'hotelsNearMe',
    });

    this._set('page.pageInfo.pageTitle', document.title);
    this._set('page.pageInfo.destinationURL', new URL(document.location.href).toString());
    this._set('page.pageInfo.language', Router.locale as string);
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageType', ADOBE_LOCATIONS_NEAR_ME);
    this._set('page.pageInfo.pageDetail1', '');
    this._set('page.pageInfo.pageDetail2', '');
    this._set('page.pageInfo.pageDetail3', '');

    // product - this needs to be set empty initially
    this._set('product[0].productInfo.productID', '');
    this.setUserData({
      honorsNumber,
      points,
      tierName: tier,
      goUserType: packages ? getGoUserType(packages) : undefined,
      country,
    });
  }

  setFeaturedLocationData({ featuredLocationsList }: FeaturedLocationsProps) {
    this._set('page.attributes.propertySearchResultList', featuredLocationsList);
  }

  trackDestinationCardClick(locationName: string, isManuallyCurated: boolean) {
    this._set(
      'page.attributes.actionDetail',
      `Carousel:Dream:${
        isManuallyCurated ? 'TopPicks' : 'Recommended'
      }:DestinationReel:${locationName}`
    );
    void this.track('categorySearch', null, true);
  }

  trackDestinationReelScrollClick(isManuallyCurated: boolean) {
    this._set(
      'page.attributes.actionDetail',
      `Carousel:Dream:${isManuallyCurated ? 'TopPicks' : 'Recommended'}:DestinationReel:Scroll`
    );
    void this.track('categorySearch', null, true);
  }

  // when a location search box is used send an event and name of location
  setLocationSearchName({ locationSearchName }: SetLocationSearchNameProps) {
    this._set('event[0].eventInfo.categoryLocationSearch', locationSearchName);
    void this.track('categorySearch', null, true);
  }

  // when interlinks title on locations page is clicked
  trackInterlinksTitleClick(data: string) {
    this._set('page.attributes.actionDetail', `Dropdown:Dream:Interlinking:${data}`);
    void this.track('categorySearch', null, true);
  }
}
