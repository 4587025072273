export type TripAdvisorRatings =
  | 'fiveAndUp'
  | 'fourAndUp'
  | 'threeAndUp'
  | 'twoAndUp'
  | 'allRatings';

export type ActiveFiltersState = {
  amenityFilters: string[];
  attributeFilters: string[];
  brandFilters: string[];
  showAvailableHotels: boolean;
  priceFilter?: Tuple<2, number>;
  ratingsFilter: TripAdvisorRatings;
};
export const defaultActiveFiltersState: ActiveFiltersState = {
  amenityFilters: [],
  attributeFilters: [],
  brandFilters: [],
  showAvailableHotels: false,
  priceFilter: undefined,
  ratingsFilter: 'allRatings',
};

export type FilterType = 'AMENITIES' | 'ATTRIBUTES' | 'BRANDS' | 'OPTIONAL' | 'PRICE' | 'RATINGS';
export type FilterModalType = 'ALL' | 'BRANDS' | 'PRICE' | 'AMENITIES' | undefined;

export const PRICE_SILDER_MAX = 500;

export const NUM_AMENITIES_SUGGESTIONS_WANTED = 4;
export const MAX_SUGGESTED_AMENITY_FILTERS_WITH_SALE_FILTER = 3;

export const HotelAttributeIds = {
  sale: 'sale',
} as const;
