import { publicRuntimeConfig } from '../constants/utils';

export const generateSigninUrl = (url: string, lang: string) => {
  const src = publicRuntimeConfig.DX_AUTH_UI.replace('/__LANG__/', `/${lang}/`);
  return `${src}?forwardPageURI=${encodeURIComponent(url)}`;
};

const GO_HILTON = '/go-hilton/';
const HONORS_LOGIN = '/hilton-honors/login/';

export const generateLoginUrl = (url: string, lang: string) =>
  url.includes(GO_HILTON)
    ? `https://${
        publicRuntimeConfig.HONORS_HOST
      }/${lang}${GO_HILTON}?forwardPageURI=${encodeURIComponent(url)}`
    : `https://${
        publicRuntimeConfig.HONORS_HOST
      }/${lang}${HONORS_LOGIN}?forwardPageURI=${encodeURIComponent(url)}`;

export const generateSignupUrl = () => publicRuntimeConfig.HONORS_JOIN_URL;
