import { defaultActiveFiltersState } from '../components/filters/filter.constants';
import { v4 as uuidv4 } from 'uuid';
import { format, addDays } from 'date-fns';
import type { QueryParameters, QueryParametersDream } from './query';

export const MOCK_DEFAULT_APP_STATE = {
  activeProperty: null,
  activeFiltersState: defaultActiveFiltersState,
  boundingBox: { east: 180, west: -180, north: 90, south: -90 },
  filteredHotelsInBounds: [],
  hasConnectingRooms: false,
  hotelsInBounds: {},
  paginationIndex: 0,
  selectedCtyhocn: null,
  showHotelImages: false,
  isListVisible: true,
  pageType: {},
  selectedCurrency: null,
  sortType: null,
  usePoints: false,
};

/** Dream Specific Query Parameters */
export const QUERY_PARAMS_DEFAULT_OBJ_DREAM: QueryParametersDream = {
  redeemPts: false,
  viewTxns: false,
  displayCurrency: '',
  adjoiningRoomStay: false,
  sortBy: null,
  activeFiltersState: defaultActiveFiltersState,
};

export const QUERY_PARAMS_DEFAULT_OBJ: QueryParameters = {
  ...QUERY_PARAMS_DEFAULT_OBJ_DREAM,
  sessionToken: uuidv4(),
  placeId: '',
  page: '',
  query: '',
  arrivalDate: format(new Date(), 'yyyy-MM-dd'),
  arrivalDay: '',
  arrivalMonth: '',
  arrivalYear: '',
  departureDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  departureDay: '',
  departureMonth: '',
  departureYear: '',
  threePartFromDate: '',
  threePartToDate: '',
  datesFlex: false,
  numRooms: 1,
  numAdults: 1,
  numChildren: 0,
  corporateCode: '',
  groupCode: '',
  travelAgentId: '',
  travelAgentRate: false,
  token: [],
  specialRateTokens: [],
  rooms: [{ adults: 1, children: 0, adultAges: [{}], childAges: [] }],
  brandCode: 'WW',
  brandName: 'hilton',
  promoCode: '',
  scenario: '',
  specPlan: [],
  employeeRate: false,
  friendsAndFamilyRate: false,
  ownerVIPRate: false,
  ownerHGVRate: false,
  primarySlug: '',
  pnd: '',
  offerId: 0,
  showBbox: false,
  fromId: '',
  requestedRatesOnly: false,
  maxPoints: undefined,
  isDebugQuadTrees: false,
  smbRate: false,
} as const;

export const MOCK_DREAM_PAGE_ATTRIBUTES_CONFIG = [
  {
    id: 'allInclusive',
    slug: 'all-inclusive',
    singularDisplay: 'All-Inclusive',
    pluralDisplay: 'All-Inclusive',
    breadcrumbDisplay: 'All-Inclusive',
    amenityIds: ['allInclusive'],
    categoryIds: [],
  },
  {
    id: 'beach',
    slug: 'beach',
    singularDisplay: 'Beach',
    pluralDisplay: 'Beach',
    breadcrumbDisplay: 'Beach',
    amenityIds: ['beach'],
    categoryIds: [],
  },
  {
    id: 'resort',
    slug: 'resorts',
    singularDisplay: 'Resort',
    pluralDisplay: 'Resorts',
    breadcrumbDisplay: 'Resorts',
    amenityIds: ['resort'],
    categoryIds: [],
  },
  {
    id: 'spa',
    slug: 'spa',
    singularDisplay: 'Spa',
    pluralDisplay: 'Spa',
    breadcrumbDisplay: 'Spa',
    amenityIds: ['spa'],
    categoryIds: [],
  },
  {
    id: 'golf',
    slug: 'golf',
    singularDisplay: 'Golf',
    pluralDisplay: 'Golf',
    breadcrumbDisplay: 'Golf',
    amenityIds: ['golf'],
    categoryIds: [],
  },
  {
    id: 'luxury',
    slug: 'luxury',
    singularDisplay: 'Luxury',
    pluralDisplay: 'Luxury',
    breadcrumbDisplay: 'Luxury',
    amenityIds: ['luxury'],
    categoryIds: [],
  },
  {
    id: 'ski',
    slug: 'ski',
    singularDisplay: 'Ski',
    pluralDisplay: 'Ski',
    breadcrumbDisplay: 'Ski',
    amenityIds: ['ski'],
    categoryIds: [],
  },
  {
    id: 'petsAllowed',
    slug: 'pet-friendly',
    singularDisplay: 'Pet-Friendly',
    pluralDisplay: 'Pet-Friendly',
    breadcrumbDisplay: 'Pet-Friendly',
    amenityIds: ['petsAllowed'],
    categoryIds: [],
  },
  {
    id: 'boutique',
    slug: 'boutique',
    singularDisplay: 'Boutique',
    pluralDisplay: 'Boutique',
    breadcrumbDisplay: 'Boutique',
    amenityIds: ['boutique'],
    categoryIds: [],
  },
  {
    id: 'evCharging',
    slug: 'ev-charging',
    singularDisplay: 'EV Charging',
    pluralDisplay: 'EV Charging',
    breadcrumbDisplay: 'EV Charging',
    amenityIds: ['evCharging'],
    categoryIds: [],
  },
  {
    id: 'casino',
    slug: 'casino',
    singularDisplay: 'Casino',
    pluralDisplay: 'Casino',
    breadcrumbDisplay: 'Casino',
    amenityIds: ['casino'],
    categoryIds: [],
  },
  {
    id: 'hotelResidence',
    slug: 'hotel-residences',
    singularDisplay: 'Hotel Residences',
    pluralDisplay: 'Hotel Residences',
    breadcrumbDisplay: 'Hotel Residences',
    amenityIds: ['hotelResidence'],
    categoryIds: [],
  },
  {
    id: 'outdoorPool',
    slug: 'outdoor-pool',
    singularDisplay: 'Outdoor Pool',
    pluralDisplay: 'Outdoor Pool',
    breadcrumbDisplay: 'Outdoor Pool',
    amenityIds: ['outdoorPool'],
    categoryIds: [],
  },
  {
    id: 'indoorPool',
    slug: 'indoor-pool',
    singularDisplay: 'Indoor Pool',
    pluralDisplay: 'Indoor Pool',
    breadcrumbDisplay: 'Indoor Pool',
    amenityIds: ['indoorPool'],
    categoryIds: [],
  },
];
