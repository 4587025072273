import { publicRuntimeConfig } from '../constants';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { BrandType } from '../utils';
import { useWrappedRouter } from './use-wrapped-router';
import { useBrandsQuery } from '../gql/queries';

type IBrandDetailsParams = {
  brandCode?: string;
  ignoreBrandSlug?: boolean;
};

type IBrandDetails = {
  code: string;
  formalName: string;
  name: string;
  url: string;
  canonicalSlug?: string | null;
  altSlugs?: string[];
  isHotelBrand?: boolean;
  hasHotels?: boolean;
  isPartnerBrand: boolean;
};

function useBrandDetails(input: { ignoreBrandSlug: true }): (brandCode: string) => IBrandDetails;
function useBrandDetails(input?: IBrandDetailsParams): IBrandDetails;
function useBrandDetails(
  input: IBrandDetailsParams = {}
): IBrandDetails | ((brandCode: string) => IBrandDetails) {
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const { data, isLoading } = useBrandsQuery({ language: 'en' }, { enabled: isBrowser });

  const brandName = queryParameters?.brandName as string;

  const getBrandDetails = (brandCode?: string): IBrandDetails => {
    let brand;
    if (!isLoading) {
      if (brandCode)
        brand = data?.brands?.find(
          (brand: BrandType) => brand?.code?.toLowerCase() === brandCode?.toLowerCase()
        );
      else if (brandName) {
        brand = data?.brands?.find(
          (brand: BrandType) => brand?.canonicalSlug?.toLowerCase() === brandName?.toLowerCase()
        );
      }
    }
    const baseUri = `${publicRuntimeConfig.OHW_BASE_URL}${router.locale}/${
      brand?.canonicalSlug || ''
    }`;

    return {
      code: brand?.code || 'WW',
      formalName: brand?.formalName || '',
      name: brand?.name || 'Hilton',
      url: brand?.url || baseUri,
      canonicalSlug: brand?.canonicalSlug,
      altSlugs: brand?.altSlugs,
      isHotelBrand: !!brand?.isHotelBrand,
      hasHotels: !!brand?.hasHotels,
      isPartnerBrand: !!brand?.isPartnerBrand,
    };
  };

  if (!input?.brandCode && input?.ignoreBrandSlug) {
    return getBrandDetails;
  }
  return getBrandDetails(input?.brandCode);
}

export { useBrandDetails };
