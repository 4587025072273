import { publicRuntimeConfig } from './utils';

export const ASSET_PREFIX_SVGS = `${publicRuntimeConfig.HILTON_ASSETS_URI}/modules/assets/svgs`;
export const MAX_PROPERTIES_PER_LIST_VIEW_PAGE = 20;
export const INTERNATIONAL_BOOKING_NUMBER = '+448705466677';
export const HGV_MAX_PRIMARYSLUG = 'hgv-max';
export const GO_HILTON_PRIMARYSLUG = 'go-hilton';
export const OHW_SEARCH_PRIMARYSLUGS = [GO_HILTON_PRIMARYSLUG, HGV_MAX_PRIMARYSLUG];
export const MAX_PRICE_PROPERTY_LIMIT = 150;
export const UP_TO_LG_BUTTON_MAX_STRING_LENGTH = 20;
