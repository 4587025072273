import { useMemo } from 'react';
import { useBrand_ShopAvailOptionsQuery } from '../gql/queries';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useWrappedRouter } from './use-wrapped-router';
import { useBrandDetails } from './use-brands';

type OfferType = {
  id: number;
  name: string;
};

type CompanyType = {
  pnd: string;
  name: string;
};

export const useGetCompanyOfferQuery = () => {
  const { router, safeQueryParams } = useWrappedRouter();
  const { code: brandCode } = useBrandDetails({});
  const language = 'en';
  /**
   * Core+ / other services do not currently support language based shopping. Therefore for now UI must disable query when ar customers are shopping since offers and pnd are not supported for AR locales.
   */
  const { data, isFetching, isLoading, error } = useBrand_ShopAvailOptionsQuery(
    {
      language,
      input: { offerId: safeQueryParams?.offerId, pnd: safeQueryParams?.pnd },
      brandCode: brandCode || 'WW',
    },
    {
      enabled:
        Boolean(
          isBrowser &&
            router.isReady &&
            (!!safeQueryParams?.offerId || Boolean(safeQueryParams?.pnd))
        ) && language === 'en',
    }
  );
  //∞
  const resultsData = useMemo(() => {
    const results: { company?: CompanyType | undefined; offer?: OfferType | undefined } = {};
    if (isFetching || isLoading) return results;
    const shopAvail = data?.brand?.shopAvailOptions || null;
    if (shopAvail?.altCorporateAccount) {
      const { pnd: pndCode, name } = shopAvail.altCorporateAccount;
      if (name !== '') {
        results.company = { pnd: pndCode, name: name?.trim() };
      }
    }

    if (shopAvail?.contentOffer) {
      const { id, name } = shopAvail.contentOffer;
      if (id !== 0) {
        results.offer = { id, name: name?.trim() };
      }
    }
    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading]);
  return { data: resultsData, isFetching, error };
};
