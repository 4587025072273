import { useEffect, useMemo } from 'react';
import { RatePlanCodes } from '../constants/rate-plan-codes';
import { useAppDispatch, useAppState } from '../providers/app-provider';
import { useGetCompanyOfferQuery } from './use-get-company-offer';

import { useWrappedRouter } from './use-wrapped-router';

export const useGetUsePoints = (invalidOrUnavailableOffer?: boolean) => {
  const { constraintErrors, safeQueryParams: queryParameters, router } = useWrappedRouter();
  const { shouldUsePoints, pageType, hasSpecialRate } = useAppState();
  const dispatch = useAppDispatch();
  const {
    data: { company, offer },
  } = useGetCompanyOfferQuery();

  const isSpecialRateSelected = useMemo(() => {
    const hasCorpCode =
      !!queryParameters?.corporateCode &&
      queryParameters?.corporateCode !== 'true' &&
      queryParameters?.corporateCode !== 'false';
    const hasPromoCode =
      !!queryParameters?.promoCode &&
      queryParameters?.promoCode !== 'true' &&
      queryParameters?.promoCode !== 'false';
    const hasOfferId =
      !!offer && queryParameters?.offerId === offer.id && queryParameters?.promoCode !== 'false';
    const hasCompanyName =
      !!company &&
      queryParameters?.pnd?.toLowerCase() === company?.pnd?.toLowerCase() &&
      queryParameters?.corporateCode !== 'false';

    const isSpecialRate = !!(
      !!(
        !!queryParameters?.groupCode ||
        hasCorpCode ||
        hasPromoCode ||
        hasOfferId ||
        hasCompanyName
      ) && !(constraintErrors?.offerId || invalidOrUnavailableOffer)
    );
    return isSpecialRate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParameters), company, offer, router.asPath]);

  //handle special rates
  useEffect(() => {
    const isSpecialRatesDeeplinked =
      !!queryParameters?.aaaRate ||
      queryParameters?.token?.includes('HCAAA') ||
      !!queryParameters?.token.includes(RatePlanCodes.AARP) ||
      queryParameters?.token?.includes('HCARP') ||
      !!queryParameters?.employeeRate ||
      !!queryParameters?.friendsAndFamilyRate ||
      !!queryParameters?.gvmtRates ||
      !!queryParameters?.ownerVIPRate ||
      !!queryParameters?.ownerHGVRate ||
      !!queryParameters?.token.includes(RatePlanCodes.SENIOR) ||
      !!queryParameters?.seniorRate ||
      queryParameters?.token?.includes('GDSSNR') ||
      !!queryParameters?.smbRate ||
      !!queryParameters?.travelAgentRate;

    if (isSpecialRateSelected !== hasSpecialRate || isSpecialRatesDeeplinked)
      dispatch({ type: 'SET_HAS_SPECIAL_RATE', payload: isSpecialRateSelected });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParameters), isSpecialRateSelected, hasSpecialRate]);
  //Handle route change
  useEffect(() => {
    if (router.isReady) {
      dispatch({ type: 'SET_USE_POINTS', payload: !!queryParameters?.redeemPts });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters?.redeemPts]);
  const arrivalDate = queryParameters?.arrivalDate;
  const isDayUseRate = !!(arrivalDate && arrivalDate === queryParameters?.departureDate);

  const isDisabled = pageType.isPointsExplorer || isDayUseRate;
  const getIsChecked = () => {
    switch (true) {
      case pageType.isPointsExplorer:
        return true;
      case isDisabled:
        return false;
      default:
        return shouldUsePoints;
    }
  };
  const usePointsData = useMemo(() => {
    const isChecked = getIsChecked();

    let infoPopupMessage: 'specialRatesDisclaimer' | 'dayUseRateDisclaimer' | '' = '';
    if (isDisabled) {
      if (isSpecialRateSelected) infoPopupMessage = 'specialRatesDisclaimer';
      else if (isDayUseRate) infoPopupMessage = 'dayUseRateDisclaimer';
    }

    return { isDisabled, isChecked, infoPopupMessage };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParameters), isSpecialRateSelected, shouldUsePoints]);

  //set initial value
  useEffect(() => {
    const isChecked = getIsChecked();
    if (shouldUsePoints !== isChecked) dispatch({ type: 'SET_USE_POINTS', payload: isChecked });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, shouldUsePoints, pageType.isPointsExplorer]);
  return usePointsData;
};
