import { useAppState } from '../providers/app-provider';
import { isPriceRangeValid } from '../components/filters/filter-utils';
import { useMemo } from 'react';
export function useDreamQueryParamString() {
  const { hasConnectingRooms, activeFiltersState, selectedCurrency, sortType, shouldUsePoints } =
    useAppState();
  const deeplinkParamString = useMemo(() => {
    let deeplinkParamString = '';
    if (hasConnectingRooms) deeplinkParamString += `&adjoiningRoomStay=${hasConnectingRooms}`;
    if (selectedCurrency) deeplinkParamString += `&displayCurrency=${selectedCurrency}`;
    if (shouldUsePoints) deeplinkParamString += `&redeemPts=${shouldUsePoints}`;
    if (sortType) deeplinkParamString += `&sortBy=${sortType}`;
    if (activeFiltersState.amenityFilters?.length)
      deeplinkParamString += `&f-amenityIds=${activeFiltersState.amenityFilters.join(',')}`;
    if (activeFiltersState.brandFilters?.length)
      deeplinkParamString += `&f-brandCodes=${activeFiltersState.brandFilters.join(',')}`;
    if (isPriceRangeValid(activeFiltersState.priceFilter))
      deeplinkParamString += `&f-price=${activeFiltersState.priceFilter?.join(',')}`;
    if (activeFiltersState.ratingsFilter !== 'allRatings')
      deeplinkParamString += `&f-tripAdvisorRatings=${activeFiltersState.ratingsFilter}`;
    return deeplinkParamString ? deeplinkParamString.replace(/^&/i, '?') : '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasConnectingRooms,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(activeFiltersState),
    selectedCurrency,
    sortType,
    shouldUsePoints,
  ]);

  return deeplinkParamString;
}
