import { useEffect } from 'react';
import { useInLanguageAutocomplete } from '../hooks/use-in-language-autocomplete';
import { useMetrics } from '../hooks/use-metrics';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useLocation } from '@dx-ui/framework-location-provider';
import { useGeocodePageQuery } from '../gql/queries';
import type { LocationAbout, LocationPageInterlink } from '../gql/types';
import { keepPreviousData } from '@tanstack/react-query';

export function useDefaultLocationsPage() {
  const metrics = useMetrics();
  const { country } = useLocation();

  const { guestInfo, isLoading: isAuthLoading } = useAuth();
  const { isInLanguageAutocomplete, language } = useInLanguageAutocomplete();

  const { data, isLoading } = useGeocodePageQuery(
    {
      language: isInLanguageAutocomplete && language ? language : 'en',
      pageType: 'DEFAULT',
      path: '/locations',
    },
    { placeholderData: keepPreviousData }
  );

  useEffect(() => {
    if (!isAuthLoading) {
      metrics.setDefaultLocationsPageData({
        honorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
        tier: guestInfo?.hhonors?.summary?.tierName || '',
        points: guestInfo?.hhonors?.summary?.totalPoints || 0,
        packages: (guestInfo?.hhonors?.packages as unknown as { packageName: string }[]) || [],
        country: country as string,
      });
      metrics.setPageData({ page: 'categoryPageView' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthLoading]);

  return {
    aboutContentBlock: data?.geocodePage?.location?.about as LocationAbout,
    isGeoCodePageLoading: isLoading,
    pageInterLinks: data?.geocodePage?.location?.pageInterlinks as LocationPageInterlink[],
  };
}
