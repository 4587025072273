import type { IRoom } from '../constants';
import { PAGES } from './constants';
import type {
  ICreateProperySearchDateInfoString,
  ICreatePropertySearchCriteriaString,
  ICreatePropertySearchRefinementsString,
  ICreateSearchChangesString,
  ICreatePropertySearchBrandResultsString,
  IRoomsObj,
  SearchRateCodeStringProps,
  IGetLocationPageNameProps,
} from './types';
import { RatePlanCodes } from '../constants/rate-plan-codes';
import { parseISO, format, differenceInCalendarDays, isSameDay } from 'date-fns';
import Router from 'next/router';
import type { ActiveFiltersState } from '../components/filters/filter.constants';

const booleanYNMap: { [key: string]: string } = {
  true: 'Y',
  false: 'N',
};

const convertBoolToYNString = (booleanUrlParam: boolean): string =>
  booleanYNMap[booleanUrlParam.toString()] || 'X';

const createPropertySearchResultListString = (ctyhocns: string[]): string =>
  ctyhocns.slice(0, 15).join(':') || '';

const createPropertySearchDateInfoString = (props: ICreateProperySearchDateInfoString): string => {
  const { arrivalDate, departureDate } = props;

  const currentDate = format(new Date(), 'MMddyyyy');
  const parsedArrivalDate = arrivalDate && format(parseISO(arrivalDate), 'MMddyyyy');
  const parsedDepartureDate = departureDate && format(parseISO(departureDate), 'MMddyyyy');

  const numNights =
    departureDate &&
    arrivalDate &&
    differenceInCalendarDays(parseISO(departureDate), parseISO(arrivalDate));
  const daysInAdvance = arrivalDate && differenceInCalendarDays(parseISO(arrivalDate), new Date());
  return `${currentDate}:${parsedArrivalDate}:${parsedDepartureDate}:${numNights}:${daysInAdvance}`;
};

const createPropertySearchCriteriaString = (props: ICreatePropertySearchCriteriaString): string => {
  const {
    numRooms = 1,
    numAdults = 1,
    numChildren = 0,
    isFlexDatesEnabled = false,
    isUsePoints = false,
    isSpecialRatesUsed = false,
    distance = 0,
    meetingCapacity = 'X',
    meetingStartDate = 'X',
    meetingEndDate = 'X',
    isMeetingFoodOrBeverage = false,
    isMeetingAudioVideo = false,
  } = props;

  return `${numRooms}:${numAdults}:${numChildren}:${convertBoolToYNString(
    isFlexDatesEnabled
  )}:${convertBoolToYNString(isUsePoints)}:${convertBoolToYNString(
    isSpecialRatesUsed
  )}:${distance}:${meetingCapacity}:${meetingStartDate}:${meetingEndDate}:${convertBoolToYNString(
    isMeetingFoodOrBeverage
  )}:${convertBoolToYNString(isMeetingAudioVideo)}`;
};
const createPropertySearchRefinementsString = (
  props: ICreatePropertySearchRefinementsString
): string => {
  const { filterType, brands, distance } = props;
  const formattedBrandString = `${filterType.toUpperCase()}:${distance}MILES`;

  if (!brands.length) return formattedBrandString;
  return brands.length === 1
    ? `${filterType.toUpperCase()}:${brands[0]}:${distance}MILES`
    : `${filterType.toUpperCase()}:${brands.join(':')}:${distance}MILES`;
};

const parseRoomsInfo = (rooms: IRoomsObj[]) => {
  const numChildren = rooms
    .map((room) => room.children)
    .reduce((previous: number, current: number) => previous + current, 0);
  const numAdults = rooms
    .map((room) => room.adults)
    .reduce((previous: number, current: number) => previous + current, 0);

  return [rooms.length, numChildren, numAdults];
};

const parseShopFormRoomsInfo = (rooms: IRoom[]) => {
  const numChildren = rooms
    .map((room) => room.children)
    .reduce((previous: number, current: number) => previous + current, 0);
  const numAdults = rooms
    .map((room) => room.adults)
    .reduce((previous: number, current: number) => previous + current, 0);

  return [rooms.length, numChildren, numAdults];
};

const createSearchChangesString = (props: ICreateSearchChangesString) => {
  const {
    aarp,
    corporateAccount,
    government,
    groupCode,
    promoCode,
    senior,
    travelAgents,
    tripleA,
    usePoints,
  } = props.rates;
  const { flexDates, from, til } = props.dates;
  const {
    arrivalDate: prevFromDate,
    departureDate: prevToDate,
    datesFlex: prevDatesFlex,
    token: prevToken,
    corporateCode: prevCorporateCode,
    groupCode: prevGroupCode,
    promoCode: prevPromoCode,
    travelAgentRate: prevTravelAgent,
    redeemPts: prevRedeemPts,
    rooms: prevRooms,
  } = props.uriParams;

  const [numRooms, numChildren, numAdults] = parseShopFormRoomsInfo(props.rooms);
  const [prevNumRooms, prevNumChildren, prevNumAdults] = parseRoomsInfo(prevRooms);
  // until widget is aware of changes versus previous search state we have to do this
  const prevTripleA = prevToken.includes(RatePlanCodes.AAA);
  const prevGovernment = prevToken.includes(RatePlanCodes.GOVERNMENT_MILITARY);
  const prevAarp = prevToken.includes(RatePlanCodes.AARP);
  const prevSeniorChanged = prevToken.includes(RatePlanCodes.SENIOR);

  const isLocationChanged = props.location !== props.prevAddress;
  const prevFromDateStr = prevFromDate ? prevFromDate : '';
  const prevToDateStr = prevToDate ? prevToDate : '';
  const isArrivalDateChanged = !isSameDay(from, parseISO(prevFromDateStr));
  const isDepartureDateChanged = !isSameDay(til, parseISO(prevToDateStr));
  const isFlexDatesChanged = flexDates !== prevDatesFlex;
  const isNumRoomsChanged = numRooms !== prevNumRooms;
  const isNumAdultsChanged = numAdults !== prevNumAdults;
  const isNumChildrenChanged = numChildren !== prevNumChildren;
  const isPromoCodeChanged = promoCode !== prevPromoCode;
  const isCorporateAccountChanged = corporateAccount !== prevCorporateCode;
  const isGroupCodeChanged = groupCode !== prevGroupCode;
  const isUsePointsChanged = usePoints !== prevRedeemPts;
  const isTripleAChanged = tripleA !== prevTripleA;
  const isAarpChanged = aarp !== prevAarp;
  const isSeniorChanged = senior !== prevSeniorChanged;
  const isGovernmentChanged = government !== prevGovernment;
  const isTravelAgentsChanged = travelAgents !== prevTravelAgent;

  return `${convertBoolToYNString(isLocationChanged)}\\${convertBoolToYNString(
    isArrivalDateChanged
  )}\\${convertBoolToYNString(isDepartureDateChanged)}\\${convertBoolToYNString(
    isFlexDatesChanged
  )}\\${convertBoolToYNString(isNumRoomsChanged)}\\${convertBoolToYNString(
    isNumAdultsChanged
  )}\\${convertBoolToYNString(isNumChildrenChanged)}\\${convertBoolToYNString(
    isPromoCodeChanged
  )}\\${convertBoolToYNString(isGroupCodeChanged)}\\${convertBoolToYNString(
    isCorporateAccountChanged
  )}\\${convertBoolToYNString(isUsePointsChanged)}\\${convertBoolToYNString(
    isTripleAChanged
  )}\\${convertBoolToYNString(isAarpChanged)}\\${convertBoolToYNString(
    isSeniorChanged
  )}\\${convertBoolToYNString(isGovernmentChanged)}\\${convertBoolToYNString(
    isTravelAgentsChanged
  )}`;
};

// Spot #10 is for Currency Selector which will always be N
const createPropertySearchRateCodeString = (props: SearchRateCodeStringProps): string =>
  `${props?.promoCode || 'N'}:${props?.groupCode || 'N'}:${
    props?.corporateCode || 'N'
  }:${convertBoolToYNString(props?.isTravelAgents || false)}:${convertBoolToYNString(
    props?.isTripleA || false
  )}:${convertBoolToYNString(props?.isAarp || false)}:${convertBoolToYNString(
    props?.isSenior || false
  )}:${convertBoolToYNString(props?.isGovernment || false)}:${convertBoolToYNString(
    props?.isUsePoints || false
  )}:N:${convertBoolToYNString(
    props?.isOwner || props?.isHGVOwner || false
  )}:${convertBoolToYNString(props?.isEmployee || false)}:${convertBoolToYNString(
    props?.isFriendsAndFamily || false
  )}`;

const createPropertySearchBrandResultsString = (
  props: ICreatePropertySearchBrandResultsString
): string => {
  const { brands, totalNumSearchResults } = props;
  const uniqueBrandCount = brands.filter(
    (value: string, index: number, brand: string[]) => brand.indexOf(value) === index
  ).length;
  return `${uniqueBrandCount}|${totalNumSearchResults}`;
};

const getSearchPageName = (brandName: string | undefined) =>
  `Browser:${Router.locale?.toUpperCase()}:${brandName || ''}:${PAGES.ADOBE_SEARCH_PAGE}:${
    PAGES.ADOBE_SEARCH_PAGE
  } Results`;

export {
  createPropertySearchResultListString,
  createPropertySearchCriteriaString,
  createPropertySearchRefinementsString,
  createSearchChangesString,
  createPropertySearchRateCodeString,
  createPropertySearchBrandResultsString,
  createPropertySearchDateInfoString,
  getSearchPageName,
};

export const getLocationPageName = (props: IGetLocationPageNameProps) => {
  const { page, country, state, city, categoryName, type, typeName } = props;
  const brandName = props.brandName || 'Multibrand';
  const pageType = props.brandName && !categoryName ? 'Brand' : 'CategoryPage';
  const pageNameParts = [
    'Browser',
    Router.locale?.toUpperCase(),
    brandName,
    pageType,
    page,
    categoryName,
    type === 'hotelsNearMe'
      ? 'NearMe'
      : type === 'region'
      ? typeName?.split(' ').join('')
      : state
      ? `${country}|${state}`
      : country,
    city,
  ];
  return pageNameParts.filter((p) => !!p).join(':');
};

export const getGoUserType: (packages: { packageName: string }[]) => string = (packages) => {
  const goUserTypes = ['TMH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF', 'HGD', 'TPH'];
  const matchingPackages: string[] = [];
  packages.forEach((pack) => {
    if (goUserTypes.includes(pack.packageName)) matchingPackages.push(pack.packageName);
  });

  return matchingPackages.join(',');
};

export const getPropertySearchDetails = (
  propertySearchDetailsString: string,
  tripAdvisorFilter?: string
): string => {
  const propertySearchDetailsStringWithoutTA = propertySearchDetailsString.includes('TA')
    ? propertySearchDetailsString.replace(/\|TA:(?:[1-4]|All)$/i, '')
    : propertySearchDetailsString;
  const tripAdvisorFilterValue = `|TA:${
    tripAdvisorFilter === 'fiveAndUp'
      ? 5
      : tripAdvisorFilter === 'fourAndUp'
      ? 4
      : tripAdvisorFilter === 'threeAndUp'
      ? 3
      : tripAdvisorFilter === 'twoAndUp'
      ? 2
      : 'All'
  }`;

  return `${propertySearchDetailsStringWithoutTA}${tripAdvisorFilterValue}`;
};
export const getPointsExplorerFilterString = (activeFiltersState: ActiveFiltersState) =>
  [...activeFiltersState.amenityFilters, ...activeFiltersState.brandFilters].join(':');
