import type { FeatureConfig } from '../gql/types';

export const FEATURE_TOGGLES: string[] = [
  'config-dream-attributes',
  'rule-ui-translate-urls',
  // SMB MVP (Context switching)
  'WEBPLAT-551',
  'NHCSEARCH-4655-blob-poc',
  'search-ui-aspect-ratios-images',
  'SearchDrawbridge',
  'search-ui-sale-filter',
  'config-rule-search-ui-suggested-filters',
  'config-ui-translate-autocomplete',
  'config-renovated-properties',
  'smbMultiBusiness',
  'search-ui-config-brand-formal-name', //NHCSEARCH-5280
];

export type Toggle = {
  config: FeatureConfig['config'];
  enabled: boolean;
  configFromRule?: FeatureConfig['config'];
};
export type NormalizedToggles = {
  [key: string]: Toggle;
};
